import { useTranslation } from 'react-i18next';

import { DatePickerProps } from './helpers';
import DatePicker from './LazyDatePicker';
import useLocale from '../useLocale';

const TimePicker = ({ internalFormat, placeholder, ...props }: DatePickerProps) => {
  const { t } = useTranslation('common');
  const { dateFormats } = useLocale();

  return (
    <DatePicker
      internalFormat="internal_time"
      showTimeSelect
      showTimeSelectOnly
      dateFormat={dateFormats.input_time}
      timeFormat={dateFormats.input_time}
      placeholder={placeholder || t('date_formats.time')}
      {...props}
    />
  );
};

export default TimePicker;
