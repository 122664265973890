import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Div } from './Generic';
import { Size, UIProps } from '../../theme/mixins';
import SC from './SC';
import useDebouncedEffect from '../useDebouncedEffect';

export interface ButtonProps extends UIProps {
  brand?: string;
  size?: Size;
  outline?: boolean;
  blank?: boolean;
  active?: boolean;
  disabled?: boolean;
  block?: boolean;
  loading?: boolean;
  borderRadius?: Size;
}

const useButton = ({
  brand, size, outline, blank, active, disabled, loading, borderRadius, ...props
}: ButtonProps, theme: any) => {
  const color = theme.getColor(brand || 'gray.200');
  const hoverColor = theme.mix(theme.lightenColor(color, 2), 'white', 0.1);
  const activeColor = theme.transparentize(theme.mix(theme.darkenColor(color, 10), color, 0.5), outline ? 0.5 : 0);

  return css`
    display: inline-block;
    background: ${color};
    border: none;
    color: ${theme.getTitleColor(color)};
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.15s ease-in-out;
    margin: 0;
    line-height: 1.25;

    ${!outline && !blank && css`
      box-shadow: inset 0 -1px 1px rgba(0, 0, 0, .075);
    `}

    ${props.block && css`
      width: 100%;
    `}

    &:hover {
      background: ${hoverColor};
      border-color: ${hoverColor};
    }

    ${outline && css`
      background: transparent;
      border: 1px solid ${color};
      color: ${theme.getColor((typeof outline === 'string' ? outline : brand) || 'gray.900')};

      &:hover {
        background: ${hoverColor};
        border-color: ${color};
        color: ${theme.getTitleColor(hoverColor)};
      }
    `}

    ${blank && css`
      background: transparent;

      &:hover {
        background: ${theme.transparentize(color, 0.7)};
        border-color: transparent;
      }
    `}

    &:active, &.active, &:focus {
      background: ${activeColor} !important;
      border-color: ${activeColor} !important;
      color: ${theme.getTitleColor(activeColor)} !important;
    }

    ${active && css`
      background: ${activeColor} !important;
      border-color: ${activeColor} !important;
      color: ${theme.getTitleColor(activeColor)} !important;
    `};

    &:focus {
      box-shadow: 0 0 0 3px ${theme.transparentize(activeColor, 0.8)};
    }

    &:disabled {
      opacity: 0.5;
    }

    ${disabled && css`
      opacity: 0.5;
      cursor: disabled;
      pointer-events: none;
    `}

    ${loading && css`
      animation: pulse 1.5s infinite;

      @keyframes pulse {
        30% {
          box-shadow: 0 0 0 0 ${theme.transparentize(theme.darkenColor(color, 20), 0.5)};
        }
        100% {
          box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
        }
      }
    `}

    ${!outline && theme.useSizes(size, 'padding', ['2px 3px', '5px 8px', '10px 16px', '15px 24px', '15px 24px', '15px 24px'])}
    ${outline && theme.useSizes(size, 'padding', ['1px 2px', '4px 7px', '9px 15px', '14px 23px', '14px 23px', '14px 23px'])}
    ${theme.useSizes(size, 'height', ['30px', '30px', '40px', '50px', '50px'])}
    ${theme.useSizes(borderRadius || size, 'border-radius', [`${theme.borderRadiuses.md}px`, `${theme.borderRadiuses.md}px`, `${theme.borderRadiuses.md}px`, `${theme.borderRadiuses.lg}px`, `${theme.borderRadiuses.xl}px`])}
    ${props && theme.useMixins(props)};
  `;
};

export const Button = styled.button.attrs((props) => ({ type: 'button', ...props }))<SC<ButtonProps>>`
  ${({ sc: props, disabled, theme }) => useButton({ ...props, disabled }, theme)}
`;

export const LinkButton = styled(({ sc, ...props }) => <Link {...props} />)<SC<ButtonProps> & LinkProps>`
  ${({ sc: props, theme }) => useButton({ ...props }, theme)}
`;

export const NavButton = styled(({ sc, ...props }) => <NavLink {...props} />)<SC<ButtonProps> & NavLinkProps>`
  ${({ sc: props, theme }) => useButton({ ...props }, theme)}
`;

// eslint-disable-next-line jsx-a11y/anchor-has-content
export const AButton = styled(({ sc, ...props }) => <a {...props} />)<SC<ButtonProps>>`
  ${({ sc: props, theme }) => useButton({ ...props }, theme)}
`;

interface TabButtonProps extends UIProps {
  active?: boolean;
}

export const TabButton = styled.button<SC<TabButtonProps>>`
  ${({ sc = {}, theme }) => css`
    background: transparent;
    border: none;
    padding: 0;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    border-radius: 0;
    padding: 13px 0 10px 0;
    height: 48px;
    color: ${theme.colors.gray[600]};
    font-weight: 500;

    &:hover {
      color: ${theme.colors.gray[900]};
      border-color: rgba(0, 0, 0, .2);
    }

    &:focus {
      background: transparent;
    }

    ${sc.active && css`
      color: ${theme.colors.gray[900]} !important;
      border-color: ${theme.colors.secondary[500]} !important;
    `}

    ${theme.useMixins(sc)};
  `}
`;

interface ConfirmButtonProps extends SC<ButtonProps> {
  onConfirm: () => any;
  render: (start: () => any, disabled: boolean) => ReactNode;
  loading?: boolean;
}

export const ConfirmButton = ({ onConfirm, render, loading = false, sc }: ConfirmButtonProps) => {
  const { t } = useTranslation();

  const [deleting, setDeleting] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const start = () => {
    setDeleting(true);
  };

  useDebouncedEffect(() => {
    if (deleting) {
      setConfirming(true);
    }
  }, 100, [deleting]);

  useDebouncedEffect(() => {
    if (confirming) {
      setDeleting(false);
      setConfirming(false);
    }
  }, 10000, [confirming]);

  if (!confirming) {
    return <>{render(start, deleting)}</>;
  }

  return (
    <Button
      onClick={() => onConfirm()}
      sc={{ loading, ...sc }}
      disabled={loading}
    >
      {t('confirm')}
    </Button>
  );
};

const ToggleButtonContainer = styled.div<SC<ButtonProps>>`
  ${({ sc, theme }) => css`
    display: ${sc && sc.block ? 'block' : 'inline-block'};
    position: relative;

    button {
      display: block;
      text-align: left;
      padding: 10px 24px 10px 10px;
    }

    ::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      width: 0;
      height: 0;
      margin-left: 8px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid ${theme.getTextColor(theme.getColor((sc && sc.brand) || 'gray.200'))};
      opacity: 0.5;
      display: inline-block;
      margin-bottom: 2px;
    }
  `}
`;

export const ToggleButton = ({ sc, ...props }: any) => (
  <ToggleButtonContainer sc={{ block: sc && sc.block }}>
    <Button type="button" sc={sc} {...props} />
  </ToggleButtonContainer>
);

export const Pill = styled(Div)`
  button:not(:first-child), a:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  button:not(:last-child), a:not(:last-child) {
    border-right-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
