import styled from 'styled-components';

import theme from '../../theme';

export const Toggle = ({ id, ...props }: any) => {
  const identifier = id || `checkbox-${Math.round(Math.random() * 1000000)}`;

  return (
    <ToggleContainer htmlFor={identifier}>
      <ToggleInput {...props} id={identifier} />
      <ToggleBackground>
        <ToggleSwitch />
      </ToggleBackground>
    </ToggleContainer>
  );
};

export const ToggleInput = styled.input.attrs((props) => ({ ...props, type: 'checkbox' }))`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
`;

const ToggleBackground = styled.span`
  position: relative;
  display: inline-block;
  border-radius: 20px;
  background: ${theme.transparentize(theme.saturate(theme.colors.gray[900], 100), 0.8)};
  width: 48px;
  height: 24px;
  cursor: pointer;
`;

const ToggleSwitch = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 8px;
  display: inline-block;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  top: 50%;
  transform: translateY(-50%) translateX(25%);
  background: ${theme.colors.white};
  transition: all 0.15s ease-in-out;
`;

export const ToggleContainer = styled.label`
  display: inline-block;
  vertical-align: middle;
  line-height: 1;

  ${ToggleInput}:disabled + ${ToggleBackground} {
    background: ${theme.transparentize(theme.saturate(theme.colors.gray[900], 100), 0.8)};
    opacity: 0.5;
    cursor: default;
  }

  ${ToggleInput}:checked + ${ToggleBackground} {
    background: ${theme.colors.success};

    ${ToggleSwitch} {
      transition: all 0.15s ease-in-out;
      transform: translateY(-50%) translateX(175%);
    }
  }

  ${ToggleInput}:checked:focus + ${ToggleBackground} {
    box-shadow: 0 0 0 3px ${theme.transparentize(theme.colors.success, 0.85)};
  }

  ${ToggleInput}:not(:checked):focus + ${ToggleBackground} {
    box-shadow: 0 0 0 3px ${theme.transparentize(theme.colors.gray[900], 0.95)};
  }
`;
