import styled, { css } from 'styled-components';

import { Div, HR, Span } from './Generic';
import { Icon } from './Icon';
import { Legend } from './Text';

interface PickerContainerProps {
  open?: boolean;
}

export const PickerContainer = styled(Div)<PickerContainerProps>`
  position: relative;
  display: block;
  margin: 0 auto;
  height: 40px;
  z-index: 10;
  text-align: left;
`;

interface PickerItemProps {
  active?: boolean;
  focus?: boolean;
}

export const PickerItem = styled.button<PickerItemProps>`
  ${({ active, focus, theme }) => css`
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    padding: ${theme.gutter / 4}px 10px;
    border-radius: ${theme.borderRadiuses.lg - 3}px;
    text-align: left;
    cursor: pointer;
    white-space: normal;
    line-height: 1.25;

    ${active && css`
      background: ${theme.colors.gray[100]};
    `}

    ${focus && css`
      background: ${theme.colors.secondary[500]};
      color: ${theme.colors.white};
    `}
  `}
`;

interface PickerDropdownProps {
  open?: boolean;
}

export const PickerDropdown = styled(Div)<PickerDropdownProps>`
  ${({ open, theme }) => css`
    border-radius: ${theme.borderRadiuses.lg}px;
    display: block;
    position: absolute;
    white-space: nowrap;
    transition: opacity .15s ease-in-out;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

    > button {
      display: flex;
      align-items: center;
      padding: 0 ${theme.gutter / 2}px;
      height: 40px;
      border: none;
      background: none;
      cursor: pointer;
      border-radius: ${theme.borderRadiuses.md}px;
      width: 100%;
      color: ${theme.colors.gray[800]};
      border: 1px solid ${theme.colors.gray[250]};
      text-align: left;

      > ${Span} {
        display: block;
        width: 100%;
        padding-right: 20px;
        white-space: nowrap;
        max-width: 100%;
        position: relative;
        min-height: 20px;

        > ${Icon}:last-child {
          // Chevron
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -8px;
          color: ${theme.colors.gray[400]};
        }
      }

      &:hover {
        border-color: ${theme.colors.gray[300]};
      }

      &:disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    > ${Div} {
      padding: 0;
      height: 0;
      width: 0;
      opacity: 0;
      transition: opacity .15s ease-in-out;

      > ${Div}:last-child {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      ${Legend} {
        padding: 0 10px;
      }

      ${HR} {
        margin: ${theme.gutter / 3}px 0;
      }
    }

    ${open && css`
      background: ${theme.colors.white};
      box-shadow: ${theme.shadows.lg};
      min-width: 150px;
      border: 1px solid ${theme.colors.gray[250]};

      > button {
        border-color: transparent !important;
        height: 39px;
        padding-left: ${theme.gutter / 2 - 1}px;
        padding-right: ${theme.gutter / 2 - 1}px;
      }

      > ${Div} {
        width: auto;
        height: auto;
        opacity: 1;
        padding: 0 ${theme.gutter / 2 - 1}px ${theme.gutter / 2 - 1}px ${theme.gutter / 2 - 1}px;
      }
    `}
  `}
`;

export default undefined;
