import { Fragment, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { A } from './Nav';
import { LI, UL } from './List';
import { Span } from './Generic';

interface ListLimiterProps {
  items: ReactNode[];
  limit?: number;
  separator?: ReactNode;
  expandUL?: boolean;
}

const ListLimiter = ({ items, separator = ', ', limit = 3, expandUL = false }: ListLimiterProps) => {
  const { t } = useTranslation('common');

  const initialItems = items.slice(0, limit);
  const restItems = items.slice(limit);

  const [expand, setExpand] = useState(false);

  const Container = expandUL && expand ? UL : Fragment;
  const Item = expandUL && expand ? LI : Fragment;

  return (
    <>
      <Container>
        {initialItems.map((item, index) => (
          <Item key={index}>
            {index > 0 && separator}
            {item}
          </Item>
        ))}

        {restItems.length > 0 && !expand && (
          <>
            <Span sc={{ muted: true }}>&hellip;</Span>
            {' '}
            <A onClick={() => setExpand(true)}>
              {` + ${restItems.length}`}
            </A>
          </>
        )}

        {expand && restItems.map((item, index) => (
          <Item key={index}>
            {!expandUL && separator}
            {item}
          </Item>
        ))}
      </Container>
      {restItems.length > 0 && expand && (
        <>
          {!expandUL && <Span sc={{ muted: true }}> • </Span>}
          <A onClick={() => setExpand(false)}>
            {t('list_limiter.less')}
          </A>
        </>
      )}
    </>
  );
};

export default ListLimiter;
