import { Fragment } from 'react';

import { Span } from './Generic';

interface NL2BRProps {
  content: string;
  maxLines?: number;
}

const NL2BR = ({ content, maxLines }: NL2BRProps) => {
  const originalLines = content.trim().split('\n');

  const lines = originalLines
    .slice(0, maxLines).join('\n') // Extract the first `maxLines` lines
    .trim() // Remove trailing whitespace
    .split('\n'); // Convert to list

  return (
    <>
      {lines.map((line, index) => (
        <Fragment key={index}>
          {index > 0 && <br />}
          {line}
        </Fragment>
      ))}
      {maxLines < originalLines.length && (
        <Span sc={{ muted: true }}> ...</Span>
      )}
    </>
  );
};

export default NL2BR;
