import { useRef } from 'react';

import { Div } from './Generic';
import Delimit from './Delimit';

const RenderCounter = ({ children }: any) => {
  const countRef = useRef(0);

  countRef.current++;

  return (
    <Div sc={{
      background: 'black', color: 'white', padding: 0.5, inlineBlock: true, mr: 1,
    }}
    >
      <Delimit>
        {children}
        {`Renders: ${countRef.current}`}
      </Delimit>
    </Div>
  );
};

export default RenderCounter;
