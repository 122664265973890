import { ChangeEvent, ClipboardEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import times from 'lodash/times';

import { BACKSPACE_KEY, InputProps } from './helpers';
import { Div } from './Generic';
import { GridContainer } from './Container';
import Input from './Input';
import SC from './SC';

interface PinCodeInputProps extends SC<InputProps> {
  value: string | null;
  onChange: (value: string) => any;
  length: number;
  name?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  match?: RegExp;
  id?: string;
}

const PinCodeInput = ({
  value, onChange, length, name, autoFocus, disabled, match = /[\d]/g, id, sc,
}: PinCodeInputProps) => {
  const splitValue = (value || '').toUpperCase().split('').slice(0, length);
  const initialValue = [...splitValue, ...times(length - splitValue.length, () => '')];

  const [pin, setPin] = useState(initialValue);

  const joinedPin = pin.join('');

  useEffect(() => {
    onChange(joinedPin);
  }, [joinedPin]);

  const handleChange = (index: number) => (
    (event: ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      const value = match ? ((text.match(match) || [])[0] || '') : text;

      setPin((pin) => {
        const newPin = [...pin];

        newPin[index] = value;

        // Clear all inputs after the current one
        newPin.forEach((value, i) => {
          if (i > index) {
            inputRef.current[i].value = '';
            newPin[i] = '';
          }
        });

        return newPin;
      });

      if (value) {
        focusInput(index + 1);
      }
    }
  );

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handlePaste = (index: number) => (
    (event: ClipboardEvent<HTMLInputElement>) => {
      const text = event.clipboardData.getData('Text').trim();
      const value = match ? (text.match(match) || []) : text.split('');

      setPin((pin) => [
        ...pin.slice(0, index),
        ...value,
        ...pin.slice(index + value.length),
      ].slice(0, length));

      focusInput(Math.min(index + value.length, length - 1));
    }
  );

  const handleKeyDown = (index: number) => (
    (event: KeyboardEvent<HTMLInputElement>) => {
      const input = inputRef.current[index];
      const charCode = (typeof event.which === 'number') ? event.which : event.keyCode;

      if (input && !input.value && charCode === BACKSPACE_KEY) {
        focusInput(index - 1);
        event.preventDefault();
      }
    }
  );

  const focusInput = (index: number) => {
    const input = inputRef.current[index];

    if (input) {
      input.focus();
    }
  };

  const inputRef = useRef([]);

  return (
    <GridContainer sc={{ columns: times(length, () => '1fr').join(' '), gutter: 0.5 }}>
      {pin.map((value, index) => (
        <Div key={index}>
          <Input
            type="text"
            value={value}
            onFocus={handleFocus}
            onChange={handleChange(index)}
            onPaste={handlePaste(index)}
            onKeyDown={handleKeyDown(index)}
            name={`${name || 'pin'}.${index}`}
            inputMode="numeric"
            autoFocus={index === splitValue.length && autoFocus}
            disabled={disabled}
            maxLength={1}
            id={id ? `${id}_${index}` : undefined}
            sc={sc}
            style={{ textAlign: 'center', fontWeight: 500 }}
            ref={(element) => { inputRef.current[index] = element; }}
          />
        </Div>
      ))}
    </GridContainer>
  );
};

export default PinCodeInput;
