import { ChangeEvent, FocusEvent, useEffect, useRef } from 'react';
import { Minus, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Button } from './Button';
import { GridContainer } from './Container';
import { HtmlInputProps } from './helpers';
import { Icon } from './Icon';
import DebouncedInput from './DebouncedInput';

interface QuantitySelectProps extends Omit<HtmlInputProps, 'onChange'> {
  quantity: number;
  onChange: (quantity: number) => void,
  maxQuantity?: number | null;
  title?: string;
}

const QuantitySelect = ({
  quantity, onChange, maxQuantity = null, name = 'quantity', maxLength = 3, title, ...props
}: QuantitySelectProps) => {
  const { t } = useTranslation('common');

  const inputRef = useRef<HTMLInputElement>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) || 0;
    const quantity = maxQuantity !== null ? Math.min(maxQuantity, value) : value;

    event.target.value = `${quantity}`;

    onChange(quantity);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();

    props.onFocus?.(event);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = `${quantity || ''}`;
    }
  }, [quantity, inputRef]);

  const maxWidth = maxLength > 0 ? maxLength * 9 + 24 : null;

  return (
    <GridContainer
      sc={{
        columns: `40px ${maxWidth ? `${maxWidth}px` : '1fr'} 40px`,
        gutter: 0.25,
        alignVertical: 'start',
      }}
      style={{ verticalAlign: 'top' }}
    >
      <Button
        onClick={() => onChange(quantity - 1)}
        sc={{ brand: 'gray.100', block: true }}
        style={{ width: 40, paddingLeft: 0, paddingRight: 0 }}
        disabled={quantity === 0}
        name={`${name}.decrement`}
        aria-label={t('quantity_select.remove_item', { title })}
      >
        <Icon>
          <Minus />
        </Icon>
      </Button>
      <DebouncedInput
        {...props}
        onChange={handleChange}
        onFocus={handleFocus}
        value={quantity || ''}
        placeholder="0"
        maxLength={maxLength}
        inputMode="numeric"
        match={/[\d]/g}
        style={{ fontWeight: 500, textAlign: 'center', maxWidth }}
        ref={inputRef}
        name={`${name}.value`}
        disabled={maxQuantity === 0}
        aria-label={t('quantity_select.quantity_for_item', { title })}
      />
      <Button
        onClick={() => onChange(quantity + 1)}
        sc={{ brand: 'gray.100', block: true }}
        style={{ width: 40, paddingLeft: 0, paddingRight: 0 }}
        disabled={maxQuantity !== null && quantity >= maxQuantity}
        name={`${name}.increment`}
        aria-label={t('quantity_select.add_item', { title })}
      >
        <Icon>
          <Plus />
        </Icon>
      </Button>
    </GridContainer>
  );
};

export default QuantitySelect;
