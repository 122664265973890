import {
  DocumentNode, MutationHookOptions, MutationTuple, OperationVariables, TypedDocumentNode,
  useMutation as useApolloMutation,
} from '@apollo/client';

function useMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
  options = options || {};

  return useApolloMutation<TData, TVariables>(mutation, options);
}

export default useMutation;
