import { FormManager } from 'react-form-state-manager';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ConfirmButton, NavButton } from './Button';
import { Div, Span } from './Generic';
import FadeIn from './FadeIn';
import Loader from './Loader';
import useMediaDevice from '../useMediaDevice';

interface FormButtonsProps {
  form: FormManager<any>;
  submitLabel?: ReactNode;
  enabled?: boolean;
  onClose?: () => void;
  closeTo?: string;
  closeLabel?: ReactNode;
  deleteLabel?: ReactNode;
  onDelete?: () => void;
  loading?: boolean;
  valid?: boolean;
  showLoader?: boolean;
  requireConfirm?: boolean;
}

const FormButtons = ({
  form, submitLabel, enabled, onClose, closeTo, closeLabel, deleteLabel,
  onDelete, loading, valid = true, showLoader, requireConfirm,
}: FormButtonsProps) => {
  const { t } = useTranslation('common');
  const device = useMediaDevice();

  const enableSubmit = typeof enabled === 'boolean' ? enabled : form.changed();

  return (
    <Div sc={{
      textAlign: ['center', 'right'],
      flex: device.isSmall && { direction: 'column-reverse' },
    }}
    >
      {onDelete && (
        <Span sc={{ pullLeft: !device.isSmall, width: ['100%', 'auto'] }}>
          <ConfirmButton
            onConfirm={onDelete}
            sc={{ width: ['100%', 'auto'], mt: [2, 0] }}
            render={(start, disabled) => (
              <Button
                onClick={() => start()}
                sc={{
                  blank: true,
                  color: disabled ? 'gray[100]' : 'gray[500]',
                  width: ['100%', 'auto'],
                  mt: [2, 0],
                }}
                style={{ order: device.isSmall ? 3 : undefined }}
                disabled={disabled}
              >
                {deleteLabel}
              </Button>
            )}
          />
        </Span>
      )}
      {' '}
      <Div sc={{ flex: device.isSmall && { direction: 'column-reverse' } }}>
        {closeTo && (
          <NavButton
            to={closeTo}
            sc={{ width: ['100%', 'auto'] }}
            exact
          >
            {closeLabel || t('forms.close')}
          </NavButton>
        )}
        {onClose && (
          <Button
            onClick={onClose}
            sc={{ width: ['100%', 'auto'] }}
          >
            {t('forms.close')}
          </Button>
        )}
        {' '}
        {!requireConfirm && (
          <Button
            type="submit"
            sc={{ brand: 'secondary', loading, width: ['100%', 'auto'], mb: [2, 0] }}
            disabled={!enableSubmit || loading || !valid}
          >
            {submitLabel}
            {' '}
            {showLoader && loading && (
              <Loader sc={{ size: 14, brand: 'secondary.200' }} />
            )}
          </Button>
        )}
        {requireConfirm && (
          <FadeIn>
            <Button
              type="submit"
              sc={{
                loading, width: ['100%', 'auto'], mb: [2, 0],
              }}
              disabled={!enableSubmit || loading || !valid}
            >
              {t('forms.confirm')}
            </Button>
          </FadeIn>
        )}
      </Div>
      {form.changed() && !valid && !loading && (
        <Div sc={{ muted: true, mt: [0, 2], mb: [2, 0] }}>
          {t('forms.form_incomplete')}
        </Div>
      )}
    </Div>
  );
};

export default FormButtons;
