import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Error } from './Alert';
import { Strong } from './Text';
import { UIProps } from '../../theme/mixins';
import { getServerErrors } from '../helpers';
import ErrorMessages from './ErrorMessages';
import SC from './SC';

interface ServerErrorProps extends SC<UIProps> {
  error: ApolloError;
  prefix?: string;
  attributes?: {
    [key: string]: string;
  };
}

const ServerErrors = ({ error, prefix, attributes, sc }: ServerErrorProps) => {
  const { t } = useTranslation('common');

  const errors = getServerErrors(error, prefix);

  const getAttributeTitle = (attribute: string) => {
    if (attributes && typeof attributes[attribute] !== 'undefined') {
      return attributes[attribute];
    }

    return null;
  };

  /**
   * Can be used to translate the attribute if the server did not return a translation.
   */
  const translateAttribute = (attribute: string) => {
    const parts = attribute.split('.');
    let last = parts.pop();

    if (!Number.isNaN(parseInt(last, 10)) && parts.length > 0) {
      last = parts.pop();
    }

    return t([`validation.attributes.${attribute}`, `validation.attributes.${last}`]);
  };

  return error ? (
    <Error sc={sc}>
      <Strong sc={{ block: true }}>{t('validation.something_wrong_with_input')}</Strong>
      {Object.keys(errors).map((attribute) => (
        <ErrorMessages
          errors={errors[attribute]}
          attribute={getAttributeTitle(attribute)}
          resolveAttributeTitle={() => translateAttribute(attribute)}
          key={attribute}
        />
      ))}
    </Error>
  ) : null;
};

export default ServerErrors;
