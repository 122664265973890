import styled, { css } from 'styled-components';

import { Card } from './Card';
import { FlexContainer } from './Container';
import { Span } from './Generic';
import { UIProps } from '../../theme/mixins';
import SC from './SC';

interface LoaderProps {
  brand?: string;
  size?: number;
}

const Loader = styled(Span)<SC<LoaderProps>>`
  ${({ sc: { brand, size = 16 } = {}, theme }) => css`
    border: 2px solid transparent;
    border-top-color: ${theme.getColor(brand || 'secondary.500')};
    border-right-color: ${theme.getColor(brand || 'secondary.500')};
    border-radius: 50%;
    width: ${size}px;
    height: ${size}px;
    animation: spin 0.6s linear infinite;
    display: inline-block;
    vertical-align: middle;

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `}
`;

interface PageLoaderProps extends SC<UIProps> {
  height?: number;
}

export const PageLoader = ({ sc, height = 140, ...props } :PageLoaderProps) => (
  <FlexContainer
    sc={{
      textAlign: 'center', justifyContent: 'center', alignItems: 'center', ...sc,
    }}
    style={{ height }}
  >
    <Card
      sc={{ padding: 2, inlineBlock: true }}
      style={{ borderRadius: '100%' }}
      {...props}
    >
      <Loader sc={{ brand: 'gray.300', block: true }} />
    </Card>
  </FlexContainer>

);

export default Loader;
