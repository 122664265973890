import { useEffect, useState } from 'react';
import PhoneInput, {
  Country, getCountryCallingCode, isSupportedCountry, parsePhoneNumber,
} from 'react-phone-number-input';
import styled, { css } from 'styled-components';

import { Locale } from '__generated__/graphql';

import { Div } from './Generic';
import { InputFallback } from './Input';
import { PhoneNumberInputProps } from './helpers';
import { assetUrl } from '../helpers';
import DebouncedInput from './DebouncedInput';
import useLocale from '../useLocale';

const getLocale = (locale: Locale) => ({
  [Locale.en]: () => import('react-phone-number-input/locale/en.json'),
  [Locale.nl]: () => import('react-phone-number-input/locale/nl.json'),
  [Locale.de]: () => import('react-phone-number-input/locale/de.json'),
  [Locale.fr]: () => import('react-phone-number-input/locale/fr.json'),
}[locale]());

const PhoneNumberInput = ({ value, country, onChange, onBlur, name, id }: PhoneNumberInputProps) => {
  const { locale } = useLocale();

  const [defaultCountry, setDefaultCountry] = useState(country);
  const [updatedCountry, setUpdatedCountry] = useState(false);

  // Update the default country to the country of the phone number,
  // so that the country remains 'active' when you empty the input.
  useEffect(() => {
    if (value) {
      const phoneNumber = parsePhoneNumber(value);

      if (phoneNumber && phoneNumber.country) {
        setDefaultCountry(phoneNumber.country);
      }
    }
  }, [value]);

  // Update the default country when the given country changes,
  // *only* when the input is empty at the time that the country is updated.
  useEffect(() => {
    setUpdatedCountry(true);
  }, [country]);

  useEffect(() => {
    if (updatedCountry) {
      if (!value) {
        setDefaultCountry(country);
      }
      setUpdatedCountry(false);
    }
  }, [updatedCountry, country, value]);

  const [labels, setLabels] = useState<{ [country: string]: string; }>();

  useEffect(() => {
    let subscribed = true;

    getLocale(locale).then((translations: any) => {
      if (subscribed) {
        // Translations for the country select.
        setLabels(Object.keys(translations).reduce((labels: any, country: Country) => {
          if (isSupportedCountry(country)) {
            labels[country] = `${translations[country]} (+${getCountryCallingCode(country)})`;
          }

          return labels;
        }, { ZZ: '' })); // ZZ is used as any other country, must be defined to avoid warnings.
      }
    });

    return () => {
      subscribed = false;
    };
  }, [locale]);

  if (!labels) {
    return <InputFallback />;
  }

  return (
    <PhoneInputContainer>
      <PhoneInput
        value={value}
        onChange={onChange}
        defaultCountry={defaultCountry as Country}
        initialValueFormat="national"
        inputComponent={DebouncedInput}
        flagUrl={assetUrl('images/flags/{XX}.svg')}
        labels={labels}
        onBlur={onBlur as any}
        name={name}
        countrySelectProps={{ name: `${name}.country` }}
        smartCaret={false}
        id={id}
      />
    </PhoneInputContainer>
  );
};

const PhoneInputContainer = styled(Div)`
  ${({ theme }) => css`
    .PhoneInput {
      position: relative;

      .PhoneInputInput {
        /* The phone number input stretches to fill all empty space */
        flex: 1;
        /* The phone number input should shrink
          to make room for the extension input */
        min-width: 0;
        padding-left: 65px;
      }

      .PhoneInputCountryIcon {
        width: 1.5em;
        height: 1em;
      }

      .PhoneInputCountryIconImg {
        /* Fixes weird vertical space above the flag icon. */
        /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
        display: block;
        /* 3rd party <SVG/> flag icons won't stretch if they have width and height.
          Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
        width: 100%;
        height: 100%;
      }

      .PhoneInputInternationalIconPhone {
        opacity: 0.8;
      }

      .PhoneInputInternationalIconGlobe {
        opacity: 0.65;
      }

      .PhoneInputCountry {
        position: absolute;
        left: 1px;
        top: 1px;
        bottom: 1px;
        border-top-left-radius: ${theme.borderRadiuses.md - 1}px;
        border-bottom-left-radius: ${theme.borderRadiuses.md - 1}px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        background: ${theme.colors.gray[100]};
      }

      .PhoneInputCountrySelect {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;
      }

      .PhoneInputCountrySelect[disabled] {
        cursor: default;
      }

      .PhoneInputCountrySelectArrow {
        display: block;
        content: '';
        width: .4em;
        height: .4em;
        margin-left: .5em;
        border-style: solid;
        border-color: ${theme.colors.gray[400]};
        border-top-width: 0;
        border-bottom-width: 1px;
        border-left-width: 0;
        border-right-width: 1px;
        transform: rotate(45deg);
        margin-top: -1px;
      }

      .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
        color: ${theme.colors.secondary[500]};
      }

      .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
        opacity: 1;
        color: ${theme.colors.secondary[500]};
      }
    }
  `}
`;

export default PhoneNumberInput;
