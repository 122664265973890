import { ReactNode, createContext, useContext } from 'react';
import Rollbar from 'rollbar/src/browser/core';

import { inProductionEnvironment } from '../helpers';
import config from '../../config';

// eslint-disable-next-line no-new
export const notifier = new Rollbar({
  enabled: inProductionEnvironment(),
  accessToken: config.rollbarAccessToken,
  captureUncaught: true,
  itemsPerMinute: 1,
  captureIp: 'anonymize',
  // The host that must be present in one of the stack frames.
  // This is used to ignore errors in browser extensions and third-party scripts.
  hostSafeList: ['atleta.cc'],
  ignoredMessages: [
    'Loading chunk .* failed', // ChunkLoadError
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  verbose: true,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
});

const RollbarContext = createContext<Rollbar>(undefined);

interface RollbarProviderProps {
  children: ReactNode;
}

const RollbarProvider = ({ children }: RollbarProviderProps) => (
  <RollbarContext.Provider value={notifier}>
    {children}
  </RollbarContext.Provider>
);

export const useRollbar = () => useContext(RollbarContext);

export default RollbarProvider;
