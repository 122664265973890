import { isMatch } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ErrorMessagesContainer } from './InputGroup';
import { Errors, ucFirst } from '../helpers';
import { UIProps } from '../../theme/mixins';
import SC from './SC';
import useLocale from '../useLocale';

interface ErrorMessagesProps extends SC<UIProps> {
  /** A translated string. */
  attribute?: string;
  /**
   * A callback that returns the translated attribute title. This prop is defined as a callback instead of a simple
   * string so that the translation function only needs to be called if necessary, preventing i18n missing key errors.
   */
  resolveAttributeTitle?: () => string;
  errors?: Errors;
}

const ErrorMessages = ({ attribute, resolveAttributeTitle, errors, ...props }: ErrorMessagesProps) => {
  const { t } = useTranslation('common');
  const { formatCurrency, formatNumber, formatDate, parseDate, dateFormats } = useLocale();

  if (errors) {
    const translate = (rule: string, parameters: any = {}) => (
      t(`validation.${rule}`, {
        ...errors[rule],
        ...parameters,
        attribute: parameters.attribute || attribute || resolveAttributeTitle?.(),
      })
    );

    const getDisplayDate = (date: string): string | null => {
      if (isMatch(date, dateFormats.internal_date)) {
        return formatDate(
          parseDate(date, { format: 'internal_date', timezone: 'UTC' }),
          { format: 'display_date' },
        );
      } if (isMatch(date, dateFormats.internal_date_time)) {
        return formatDate(
          parseDate(date, { format: 'internal_date_time', timezone: 'UTC' }),
          { format: 'display_date_time' },
        );
      }

      return null;
    };

    const messages: any = {
      'min.list': (parameters: any) => (
        translate('min.list', { ...parameters, min: formatNumber(parameters.min) })
      ),
      'min.string': (parameters: any) => (
        translate('min.string', { ...parameters, min: formatNumber(parameters.min) })
      ),
      'max.list': (parameters: any) => (
        translate('max.list', { ...parameters, max: formatNumber(parameters.max) })
      ),
      'max.string': (parameters: any) => (
        translate('max.string', { ...parameters, max: formatNumber(parameters.max) })
      ),
      'gt.money': (parameters: any) => (
        translate('gt.numeric', { ...parameters, min: formatCurrency(parameters.min) })
      ),
      'gt.numeric': (parameters: any) => (
        translate('gt.numeric', { ...parameters, min: formatNumber(parameters.min) })
      ),
      'gt.string': (parameters: any) => (
        translate('gt.string', { ...parameters, min: t(`validation.attributes.${parameters.min}`) })
      ),
      'gte.money': (parameters: any) => (
        translate('gte.numeric', { ...parameters, min: formatCurrency(parameters.min) })
      ),
      'gte.numeric': (parameters: any) => (
        translate('gte.numeric', { ...parameters, min: formatNumber(parameters.min) })
      ),
      'gte.string': (parameters: any) => (
        translate('gte.string', { ...parameters, min: t(`validation.attributes.${parameters.min}`) })
      ),
      'lt.money': (parameters: any) => (
        translate('lt.numeric', { ...parameters, max: formatCurrency(parameters.max) })
      ),
      'lt.numeric': (parameters: any) => (
        translate('lt.numeric', { ...parameters, max: formatNumber(parameters.max) })
      ),
      'lt.string': (parameters: any) => (
        translate('lt.string', { ...parameters, max: t(`validation.attributes.${parameters.max}`) })
      ),
      'lte.money': (parameters: any) => (
        translate('lte.numeric', { ...parameters, max: formatCurrency(parameters.max) })
      ),
      'lte.numeric': (parameters: any) => (
        translate('lte.numeric', { ...parameters, max: formatNumber(parameters.max) })
      ),
      'lte.string': (parameters: any) => (
        translate('lte.string', { ...parameters, max: t(`validation.attributes.${parameters.max}`) })
      ),
      max_quantity: (parameters: any) => (
        translate(parameters.max > 0 ? 'max_quantity' : 'unavailable', parameters)
      ),
      before: (parameters: any) => translate('before', {
        ...parameters,
        date: getDisplayDate(parameters[0] || parameters.date)
          || t(`validation.attributes.${parameters[0] || parameters.date}`),
      }),
      before_or_equal: (parameters: any) => translate('before', {
        ...parameters,
        date: getDisplayDate(parameters[0] || parameters.date)
          || t(`validation.attributes.${parameters[0] || parameters.date}`),
      }),
      after: (parameters: any) => translate('after', {
        ...parameters,
        date: getDisplayDate(parameters[0] || parameters.date)
          || t(`validation.attributes.${parameters[0] || parameters.date}`),
      }),
      after_or_equal: (parameters: any) => translate('after', {
        ...parameters,
        date: getDisplayDate(parameters[0] || parameters.date)
            || t(`validation.attributes.${parameters[0] || parameters.date}`),
      }),
      manual_ticket_number_registration_invalid: (parameters: any) => (
        translate('manual_ticket_number_registration_invalid', {
          ...parameters,
          registrationNumber: parameters.registration_number,
        })
      ),
      manual_ticket_number_invalid: (parameters: any) => (
        translate('manual_ticket_number_invalid', {
          ...parameters,
          ticketNumber: parameters.ticket_number,
        })
      ),
      manual_ticket_number_taken: (parameters: any) => (
        translate('manual_ticket_number_taken', {
          ...parameters,
          ticketNumber: parameters.ticket_number,
        })
      ),
      manual_ticket_number_registration_duplicate: (parameters: any) => (
        translate('manual_ticket_number_registration_duplicate', {
          ...parameters,
          registrationNumber: parameters.registration_number,
        })
      ),
      manual_ticket_number_duplicate: (parameters: any) => (
        translate('manual_ticket_number_duplicate', {
          ...parameters,
          ticketNumber: parameters.ticket_number,
        })
      ),
      invalid: (parameters: any) => parameters.message,
    };

    const getMessage = (rule: string) => (
      // Capitalize the first character because many of the attribute translations are lowercase
      ucFirst(messages[rule]?.(errors[rule]) || translate(rule, errors[rule]))
    );

    // If 'required' is one of the errors, don't show any other errors
    return (
      <ErrorMessagesContainer {...props}>
        {errors.required && getMessage('required')}
        {!errors.required && Object.keys(errors).map((rule) => (
          <div key={rule}>
            {getMessage(rule)}
          </div>
        ))}
      </ErrorMessagesContainer>
    );
  }

  return null;
};

export default ErrorMessages;
