import {
  ChangeEvent, FocusEvent, KeyboardEvent, RefObject, forwardRef, useCallback, useEffect, useRef,
} from 'react';
import { flushSync } from 'react-dom';

import { ENTER_KEY, HtmlInputProps, InputProps } from './helpers';
import Input from './Input';
import SC from './SC';

export interface DebouncedInputProps extends HtmlInputProps, SC<InputProps> {
  match?: RegExp;
  delay?: number;
  format?: (value: string) => string;
  // Below is a hack due to incompatibility issues
  ref?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement>;
}

// Allows the user to type anything, but debounces the parent state update to improve performance
const DebouncedInput = forwardRef(({
  value, onChange, onBlur, onKeyPress, match = undefined, format = undefined, delay = 200, ...props
}: DebouncedInputProps, ref: RefObject<HTMLInputElement>) => {
  const timerRef = useRef<number>();
  const changeEventRef = useRef<ChangeEvent<HTMLInputElement> | null>();

  const pushChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    changeEventRef.current = event;

    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      popChangeEvent();
    }, delay);
  };

  // Clear the timer on unmount
  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      window.clearTimeout(timer);
    };
  }, []);

  const popChangeEvent = useCallback(() => {
    if (changeEventRef.current) {
      onChange?.(changeEventRef.current);
      changeEventRef.current = null;
    }

    window.clearTimeout(timerRef.current);
  }, [onChange]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (match) {
      event.target.value = (event.target.value.match(match) || []).join('');
    }

    pushChangeEvent(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    event.persist();

    if (format) {
      event.target.value = format(event.target.value);
    }

    // In case the input is blurred before the last onChange event has propagated up,
    // pop the most recent onChange event synchronously.
    // For example: when the user presses submit while the input is still focused,
    // make sure that the latest input value is synced to the form state before doing
    // anything with the data.
    flushSync(() => {
      popChangeEvent();
    });

    onBlur?.(event);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    // In case the the user presses enter before the last onChange event has propagated up,
    // pop the most recent onChange event synchronously.
    const charCode = (typeof event.which === 'number') ? event.which : event.keyCode;

    if (charCode === ENTER_KEY) {
      flushSync(() => {
        popChangeEvent();
      });
    }

    onKeyPress?.(event);
  };

  return (
    <Input
      defaultValue={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      {...props}
      ref={ref}
    />
  );
});

export default DebouncedInput;
