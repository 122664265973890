import { Children, HTMLAttributes, ReactNode, Ref, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Div, HR } from './Generic';
import { FlexProps, GridProps, UIProps } from '../../theme/mixins';
import SC from './SC';

export interface ContainerProps {
  compact?: boolean;
}

// eslint-disable-next-line max-len
export interface SectionProps extends SC<ContainerProps & UIProps>, HTMLAttributes<HTMLDivElement> {}

export const Section = forwardRef(
  ({ children, sc = {}, ...props }: SectionProps, ref: Ref<any>) => (
    <SectionContainer sc={sc} ref={ref} {...props}>
      <Container sc={{ compact: sc.compact }}>
        {children}
      </Container>
    </SectionContainer>
  ),
);

export const Container = styled(Div)<SC<ContainerProps>>`
  ${({ sc: { compact } = {}, theme }) => css`
    margin: 0 auto;
    width: 100%;
    max-width: ${compact ? theme.containerWidth.compact : theme.containerWidth.full}px;

    @media ${theme.devices.sm} {
      padding: 0 ${theme.gutter / 2}px;
    }

    @media ${theme.devices.md} {
      padding: 0 ${theme.gutter}px;
    }
  `}
`;

export const SectionContainer = styled(Div)`
  ${({ theme }) => css`
    @media ${theme.devices.sm} {
      padding: ${theme.gutter}px 0;
    }

    @media ${theme.devices.md} {
      padding: ${theme.gutter * 1.5}px 0;
    }

    & + & {
      border-top: 1px solid rgba(0, 0, 0, .1);
    }
  `}
`;

export const GridContainer = styled(Div)<SC<GridProps>>`
  ${({ sc: props = {}, theme }) => css`
    display: grid;
    grid-template-columns: 1fr;

    > ${HR} {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    ${theme.useGrid(props)}
  `}
`;

export const StackItem = styled(Div)`
  padding: 24px 0;
`;

export const Stack = ({ children }: { children: ReactNode }) => {
  const items = Children.toArray(children);
  return (
    <>
      {items.map((item) => <StackItem>{item}</StackItem>)}
    </>
  );
};

export interface AutoGridContainerProps extends GridProps {
  colMinWidth?: string;
  colMaxWidth?: string;
}

/**
 * Device-friendly grid
 */
export const AutoGridContainer = styled(GridContainer)<SC<AutoGridContainerProps>>`
  ${({ sc: { colMinWidth = '300px', colMaxWidth = '1fr' } = {}, theme }) => css`
    @media ${theme.devices.sm} {
      grid-template-columns:  repeat(auto-fit, minmax(200px, 1fr));
    }

    @media ${theme.devices.md} {
      grid-template-columns: repeat(auto-fit, minmax(${colMinWidth}, ${colMaxWidth}));
    }
  `}
`;

export const FlexContainer = styled(Div)<SC<FlexProps['flex']>>`
  ${({ sc: flex = {}, theme }) => theme.useFlex({ flex })}
`;
