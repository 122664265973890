import { ArrowLeft, ArrowRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Div, Span } from './Generic';
import { Icon } from './Icon';
import { LinkButton } from './Button';
import { Size } from '../../theme/mixins';
import { getUrlParams, mergeUrlParams } from '../helpers';
import useLocale from '../useLocale';
import useScroll from '../useScroll';

export interface PagerProps {
  pageCount?: number;
  disableNext?: boolean;
  size?: Size;
  scroll?: boolean;
}

const Pager = ({ pageCount, disableNext, size, scroll, ...props }: PagerProps) => {
  const { t } = useTranslation('common');
  const { formatNumber } = useLocale();
  const { scrollToTop } = useScroll();

  const page = parseInt(getUrlParams().page, 10) || 1;
  const style = { minWidth: size === 'sm' ? 40 : 50 };

  const handleClick = () => {
    if (scroll) {
      scrollToTop();
    }
  };

  const hasPageCount = typeof pageCount !== 'undefined';

  return (
    <PagerContainer {...props}>
      <Span sc={{ mr: hasPageCount ? 3 : undefined }}>
        <LinkButton
          to={mergeUrlParams({ page: Math.max(1, page - 1) })}
          sc={{
            outline: true, brand: 'gray.300', color: 'gray.800', size, disabled: page <= 1,
          }}
          style={style}
          onClick={handleClick}
        >
          <Icon>
            <ArrowLeft />
          </Icon>
          {!hasPageCount && ` ${t('pager.previous')}`}
        </LinkButton>
      </Span>
      {' '}
      {hasPageCount && (
        <>
          {' '}
          {page > 1 && (
            <LinkButton
              to={mergeUrlParams({ page: 1 })}
              sc={{ blank: true, size }}
              style={style}
              onClick={handleClick}
            >
              {formatNumber(1)}
            </LinkButton>
          )}
          {' '}
          {page > 2 && pageCount > 3 && <Span sc={{ ml: 1, mr: 1 }}>&hellip;</Span>}
          {page === pageCount && page > 2 && (
            <LinkButton
              to={mergeUrlParams({ page: pageCount - 1 })}
              sc={{ blank: true, size }}
              style={style}
              onClick={handleClick}
            >
              {formatNumber(pageCount - 1)}
            </LinkButton>
          )}
          {' '}
          <LinkButton
            to={mergeUrlParams({ page })}
            sc={{ brand: 'secondary', size }}
            style={style}
            onClick={handleClick}
          >
            {formatNumber(page)}
          </LinkButton>
          {' '}
          {page === 1 && page < pageCount - 1 && (
            <LinkButton
              to={mergeUrlParams({ page: 2 })}
              sc={{ blank: true, size }}
              style={style}
              onClick={handleClick}
            >
              {formatNumber(2)}
            </LinkButton>
          )}
          {page < pageCount - 1 && pageCount > 3 && <Span sc={{ ml: 1, mr: 2 }}>&hellip;</Span>}
          {' '}
          {page < pageCount && (
            <LinkButton
              to={mergeUrlParams({ page: pageCount })}
              sc={{ blank: true, size }}
              style={style}
              onClick={handleClick}
            >
              {formatNumber(pageCount)}
            </LinkButton>
          )}
        </>
      )}
      {!hasPageCount && (
        <Span sc={{ mx: 2 }}>
          {t('pager.page_n', { page: formatNumber(page) })}
        </Span>
      )}
      {' '}
      <Span sc={{ ml: hasPageCount ? 3 : undefined }}>
        <LinkButton
          to={mergeUrlParams({ page: page + 1 })}
          sc={{
            outline: true,
            brand: 'gray.300',
            color: 'gray.800',
            size,
            disabled: disableNext || (hasPageCount && page >= pageCount),
          }}
          style={style}
          onClick={handleClick}
        >
          {!hasPageCount && `${t('pager.next')} `}
          <Icon>
            <ArrowRight />
          </Icon>
        </LinkButton>
      </Span>
    </PagerContainer>
  );
};

export const PagerContainer = styled(Div)`
  text-align: center;
  user-select: none;
`;

export default Pager;
