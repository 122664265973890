import { DetailedHTMLProps, TextareaHTMLAttributes, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { InputProps } from './helpers';
import SC from './SC';
import useInput from './useInput';

interface TextAreaContainerProps extends InputProps {
  minHeight?: number;
  maxHeight?: number;
}

const TextAreaContainer = styled.textarea<SC<TextAreaContainerProps>>`
  ${({ sc: { size, minHeight = 80, maxHeight } = {}, theme }) => css`
    resize: none;
    overflow: auto;

    ${minHeight && css`
      min-height: ${minHeight}px;
    `}

    ${maxHeight && css`
      max-height: ${maxHeight}px;
    `}

    ${useInput}
    ${theme.useSizes(size, 'padding', ['5px', '5px', '10px', '10px', '10px'])}
  `}
`;

type TextAreaAttributes = TextareaHTMLAttributes<HTMLTextAreaElement>;
type TextAreaHTMLProps = DetailedHTMLProps<TextAreaAttributes, HTMLTextAreaElement>;

interface TextAreaProps extends SC<TextAreaContainerProps>, TextAreaHTMLProps { }

const TextArea = (props: TextAreaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    const textArea = textAreaRef.current;
    const offset = textArea.offsetHeight - textArea.clientHeight;

    textArea.style.height = `${textArea.scrollHeight + offset}px`;

    const inputListener = () => {
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight + offset}px`;
    };

    textArea.addEventListener('input', inputListener);

    return () => {
      textArea.removeEventListener('input', inputListener);
    };
  }, []);

  return <TextAreaContainer {...props} ref={textAreaRef} />;
};

export default TextArea;
