import { DetailedHTMLProps, ReactNode, RefObject, SelectHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { InputProps } from './helpers';
import { TextProps } from '../../theme/mixins';
import SC from './SC';
import useInput from './useInput';

interface SelectInputProps extends InputProps, TextProps {
  hasValue?: boolean;
}

export const SelectWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;

    ::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 12px;
      width: .4em;
      height: .4em;
      border-style: solid;
      border-color: ${theme.colors.gray[400]};
      border-top-width: 0;
      border-bottom-width: 1px;
      border-left-width: 0;
      border-right-width: 1px;
      transform: rotate(45deg);
      display: inline-block;
      margin-left: 8px;
      margin-top: -5px;
    }
  `}
`;

export const SelectInput = styled.select<SC<SelectInputProps>>`
  ${({ sc: { hasValue, size, ...props } = {}, theme }) => css`
    appearance: none;
    text-overflow: ellipsis;
    ${useInput}

    ${hasValue === false && css`
      color: ${theme.colors.gray[400]};
    `}

    ${theme.useSizes(size, 'padding', ['0 5px', '0 5px', '0 10px', '0 10px', '0 10px'])}
    ${theme.useSizes(size, 'height', ['30px', '30px', '40px', '50px', '50px'])}
    ${theme.useSizes(size, 'min-height', ['30px', '30px', '40px', '50px', '50px'])}
    ${theme.useSpacing(props)}

    ${props && theme.useText(props)}

    padding-right: 24px !important;
  `}
`;

// eslint-disable-next-line max-len
type HtmlSelectProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

export interface SelectProps extends SC<SelectInputProps>, HtmlSelectProps {
  children?: ReactNode;
  // Below is a hack due to incompatibility issues
  ref?: ((instance: HTMLSelectElement) => void) | RefObject<HTMLSelectElement>;
}

const Select = ({ children, ...props }: SelectProps) => (
  <SelectWrapper>
    <SelectInput {...props}>
      {children}
    </SelectInput>
  </SelectWrapper>
);

export default Select;
