import { Suspense, lazy } from 'react';

import { DatePickerProps } from './helpers';
import { InputFallback } from './Input';

const LazyDatePicker = lazy(() => import('./DatePicker'));

const DatePicker = (props: DatePickerProps) => (
  <Suspense fallback={<InputFallback />}>
    <LazyDatePicker {...props} />
  </Suspense>
);

export default DatePicker;
