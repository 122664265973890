import { LinkProps } from 'react-router-dom';
import { ReactNode, forwardRef, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Div, HR } from './Generic';
import { Link, LinkBlock } from './Nav';
import { UIProps } from '../../theme/mixins';
import { isInViewport } from '../helpers';
import SC from './SC';
import useScroll from '../useScroll';

interface CardProps {
  hasPanels?: boolean;
  hasSidePanel?: boolean;
  placeholder?: boolean;
  active?: boolean;
  outline?: string;
}

export const Card = styled(Div)<SC<CardProps>>`
  ${({
    sc: {
      hasPanels, placeholder, active, outline, hasSidePanel, ...props
    } = {},
    theme,
  }) => css`
    display: block;
    border-radius: ${theme.borderRadiuses.xl}px;
    position: relative;
    transition: box-shadow 0.1s ease-in-out, border-color 0.1s ease-in-out, opacity 0.1s ease-in-out 0.15s;
    border-color: transparent;
    padding: ${theme.gutter * 0.75}px;

    @media ${theme.devices.md} {
      padding: ${theme.gutter}px;
    }

    ${!outline && css`
      background: ${theme.colors.white};
    `}

    ${!outline && !placeholder && css`
      box-shadow: ${theme.shadows.sm};
    `}

    ${placeholder && css`
      background: rgba(0, 0, 0, .04);
    `}

    ${hasPanels && css`
      padding: 0 !important;

      > * {
        padding: ${theme.gutter * 0.75}px;

        @media ${theme.devices.md} {
          padding: ${theme.gutter}px;
        }
      }

      > * + * {
        border-top: 1px solid rgba(0, 0, 0, .1);
      }
    `};


    ${hasSidePanel && css`
      padding: 0 !important;
      display: grid;
      grid-template-columns: 250px 1fr;

      @media ${theme.devices.sm} {
        grid-template-columns: 1fr;

        > div:first-of-type {
          border-radius: ${theme.borderRadiuses.xl}px ${theme.borderRadiuses.xl}px 0 0;
          border-bottom: 1px solid ${theme.colors.gray[200]};
        }

        > *:last-child {
          flex: 100%;
        }
      }

      @media ${theme.devices.md} {
        grid-template-columns: 250px 1fr;

        > div:first-of-type {
          border-radius: ${theme.borderRadiuses.xl}px 0 0 ${theme.borderRadiuses.xl}px;
          border-right: 1px solid ${theme.colors.gray[200]};
          border-bottom: 0;
        }

        > *:last-child {
          border-radius: 0 ${theme.borderRadiuses.xl}px ${theme.borderRadiuses.xl}px 0;
          flex: 100%;
        }
      }

      > div:first-of-type {
        background: ${theme.colors.gray[50]};
      }

      > * {
        padding: ${theme.gutter * 0.75}px;

        @media ${theme.devices.md} {
          padding: ${theme.gutter}px;
        }
      }
    `}

    ${outline && css`
      border: 1px solid ${theme.getColor(outline)};
    `}

    ${active && css`
      ${!outline && css`
        box-shadow: ${theme.shadows.lg};
      `}
    `}

    ${(hasPanels) && css`
      > :first-child {
        border-top-left-radius: ${theme.borderRadiuses.xl}px;
        border-top-right-radius: ${theme.borderRadiuses.xl}px;
      }

      > :last-child {
        border-bottom-left-radius: ${theme.borderRadiuses.xl}px;
        border-bottom-right-radius: ${theme.borderRadiuses.xl}px;
      }
    `}

    ${HR} {
      @media ${theme.devices.md} {
        margin: ${theme.gutter}px 0;
      }
    }

    ${props && theme.useSpacing(props)}
    ${props && theme.useFlex(props)}
    ${props && theme.useText(props)}
  `}
`;

interface NavCardProps extends LinkProps, SC<CardProps & UIProps> {
  ariaLabel?: string;
}

export const NavCard = forwardRef(
  ({ sc, to, id, onClick, children, ariaLabel }: NavCardProps, ref: any) => (
    <NavCardContainer sc={sc} id={id} ref={ref}>
      <CardLink to={to} onClick={onClick} aria-label={ariaLabel} />
      {children}
    </NavCardContainer>
  ),
);

const CardLink = styled(Link)``;

const NavCardContainer = styled(Card)`
  ${({ sc: { hasPanels, outline, active } = {}, theme }) => css`
    ${!active && css`
      &:hover {
        ${!outline && css`
          box-shadow: ${theme.shadows.md};
        `}

        ${outline && css`
          border-color: ${theme.colors.link} !important;
        `}
      }
    `}

    > ${CardLink} {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 200;
      border: none;
    }

    ${hasPanels && css`
      > ${CardLink} + * {
        border-top: none;
      }
    `}

    a, button, label {
      position: relative;
      z-index: 300;
    }
  `}
`;

interface CollapsibleNavCardProps {
  to: string;
  open: boolean;
  muted?: boolean;
  preview: ReactNode;
  body: ReactNode;
  id?: string;
  padding?: boolean;
  ariaLabel?: string
  sc?: CardProps;
}

let collapsibleNavCardCounter = 0;

export const CollapsibleNavCard = ({
  to, open, muted = false, preview, body, id, padding = true, ariaLabel, sc,
}: CollapsibleNavCardProps) => {
  const { scrollTo } = useScroll();

  const idRef = useRef<string>();
  if (typeof idRef.current === 'undefined') {
    // Don't want to increment the counter unnecessarily
    idRef.current = id || `CollapsibleNavCard_${collapsibleNavCardCounter++}`;
  }

  const containerRef = useRef<any>();

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (open && containerRef.current && !isInViewport(containerRef.current, 0.2)) {
        // If less than 20% of the element is visible in the viewport, scroll to it
        scrollTo(idRef.current, { gutter: 0.5 });
      }
    });

    return () => window.clearTimeout(timer);
  }, [open, scrollTo]);

  const Container = open ? Card : NavCard;

  const props = {
    ...!open && {
      to,
      ariaLabel,
    },
  };

  const hasPanels = typeof sc?.hasPanels !== 'undefined' ? sc.hasPanels : true;

  return (
    <Container
      sc={{ active: open, muted, hasPanels, ...sc }}
      id={idRef.current}
      ref={containerRef}
      {...props}
    >
      {open && (
        <>
          <LinkBlock
            to={to}
            sc={{ padding: !padding ? 0 : undefined }}
            ariaLabel={ariaLabel}
          >
            <Div sc={{ padding: padding ? [0, 2] : 0 }}>
              {preview}
            </Div>
          </LinkBlock>
          <Div sc={{ padding: padding ? (hasPanels ? [4, 6] : [0, 2]) : 0 }}>
            {body}
          </Div>
        </>
      )}
      {!open && preview}
    </Container>
  );
};
