import { ReactNode, Suspense, lazy } from 'react';
import styled, { css } from 'styled-components';

import { Span } from './Generic';
import SC from './SC';

const Communication = lazy(() => import('./Icons/Communication'));
const Customization = lazy(() => import('./Icons/Customization'));
const Dashboard = lazy(() => import('./Icons/Dashboard'));
const Finance = lazy(() => import('./Icons/Finance'));
const Mailchimp = lazy(() => import('./Icons/Mailchimp'));
const Registration = lazy(() => import('./Icons/Registration'));
const Resale = lazy(() => import('./Icons/Resale'));
const Ticket = lazy(() => import('./Icons/Ticket'));
const Running = lazy(() => import('./Icons/Running'));
const TrailRunning = lazy(() => import('./Icons/TrailRunning'));
const Cycling = lazy(() => import('./Icons/Cycling'));
const GravelCycling = lazy(() => import('./Icons/GravelCycling'));
const Mountainbiking = lazy(() => import('./Icons/Mountainbiking'));
const Cyclocross = lazy(() => import('./Icons/Cyclocross'));
const Athletics = lazy(() => import('./Icons/Athletics'));
const Biathlon = lazy(() => import('./Icons/Biathlon'));
const Climbing = lazy(() => import('./Icons/Climbing'));
const CombatSport = lazy(() => import('./Icons/CombatSport'));
const Duathlon = lazy(() => import('./Icons/Duathlon'));
const Fishing = lazy(() => import('./Icons/Fishing'));
const Fitness = lazy(() => import('./Icons/Fitness'));
const Football = lazy(() => import('./Icons/Football'));
const Golf = lazy(() => import('./Icons/Golf'));
const Handball = lazy(() => import('./Icons/Handball'));
const Hockey = lazy(() => import('./Icons/Hockey'));
const HorseRiding = lazy(() => import('./Icons/HorseRiding'));
const IceSkating = lazy(() => import('./Icons/IceSkating'));
const Kitesurfing = lazy(() => import('./Icons/Kitesurfing'));
const ObstacleRun = lazy(() => import('./Icons/ObstacleRun'));
const Rowing = lazy(() => import('./Icons/Rowing'));
const Sailing = lazy(() => import('./Icons/Sailing'));
const Skiing = lazy(() => import('./Icons/Skiing'));
const Surfing = lazy(() => import('./Icons/Surfing'));
const Swimming = lazy(() => import('./Icons/Swimming'));
const Tennis = lazy(() => import('./Icons/Tennis'));
const Triathlon = lazy(() => import('./Icons/Triathlon'));
const Volleyball = lazy(() => import('./Icons/Volleyball'));
const Walking = lazy(() => import('./Icons/Walking'));
const Yoga = lazy(() => import('./Icons/Yoga'));
const Other = lazy(() => import('./Icons/Other'));

export interface IconProps {
  size?: number;
  strong?: boolean;
}

export const Icon = styled(Span)<SC<IconProps>>`
  ${({ sc: { size = 1, strong } = {} }) => css`
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;

    > * {
      height: ${size}em;
      width: ${size}em;
      display: block;
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    strong & svg {
      stroke-width: 2.5;
    }

    ${strong && css`
      svg {
        stroke-width: 2.5;
      }
    `}
  `}
`;

const LazyIcon = ({ children }: { children: ReactNode; }) => (
  <Suspense
    fallback={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" />}
  >
    {children}
  </Suspense>
);

export const Icons = {
  Ticket: () => <LazyIcon><Ticket /></LazyIcon>,
  Resale: () => <LazyIcon><Resale /></LazyIcon>,
  Finance: () => <LazyIcon><Finance /></LazyIcon>,
  Communication: () => <LazyIcon><Communication /></LazyIcon>,
  Customization: () => <LazyIcon><Customization /></LazyIcon>,
  Registration: () => <LazyIcon><Registration /></LazyIcon>,
  Dashboard: () => <LazyIcon><Dashboard /></LazyIcon>,
  Mailchimp: () => <LazyIcon><Mailchimp /></LazyIcon>,

  // Sport types
  Running: () => <LazyIcon><Running /></LazyIcon>,
  TrailRunning: () => <LazyIcon><TrailRunning /></LazyIcon>,
  Cycling: () => <LazyIcon><Cycling /></LazyIcon>,
  GravelCycling: () => <LazyIcon><GravelCycling /></LazyIcon>,
  Mountainbiking: () => <LazyIcon><Mountainbiking /></LazyIcon>,
  Cyclocross: () => <LazyIcon><Cyclocross /></LazyIcon>,
  Athletics: () => <LazyIcon><Athletics /></LazyIcon>,
  Biathlon: () => <LazyIcon><Biathlon /></LazyIcon>,
  Climbing: () => <LazyIcon><Climbing /></LazyIcon>,
  CombatSport: () => <LazyIcon><CombatSport /></LazyIcon>,
  Duathlon: () => <LazyIcon><Duathlon /></LazyIcon>,
  Fishing: () => <LazyIcon><Fishing /></LazyIcon>,
  Fitness: () => <LazyIcon><Fitness /></LazyIcon>,
  Football: () => <LazyIcon><Football /></LazyIcon>,
  Golf: () => <LazyIcon><Golf /></LazyIcon>,
  Handball: () => <LazyIcon><Handball /></LazyIcon>,
  Hockey: () => <LazyIcon><Hockey /></LazyIcon>,
  HorseRiding: () => <LazyIcon><HorseRiding /></LazyIcon>,
  IceSkating: () => <LazyIcon><IceSkating /></LazyIcon>,
  Kitesurfing: () => <LazyIcon><Kitesurfing /></LazyIcon>,
  ObstacleRun: () => <LazyIcon><ObstacleRun /></LazyIcon>,
  Rowing: () => <LazyIcon><Rowing /></LazyIcon>,
  Sailing: () => <LazyIcon><Sailing /></LazyIcon>,
  Skiing: () => <LazyIcon><Skiing /></LazyIcon>,
  Surfing: () => <LazyIcon><Surfing /></LazyIcon>,
  Swimming: () => <LazyIcon><Swimming /></LazyIcon>,
  Tennis: () => <LazyIcon><Tennis /></LazyIcon>,
  Triathlon: () => <LazyIcon><Triathlon /></LazyIcon>,
  Volleyball: () => <LazyIcon><Volleyball /></LazyIcon>,
  Walking: () => <LazyIcon><Walking /></LazyIcon>,
  Yoga: () => <LazyIcon><Yoga /></LazyIcon>,
  Other: () => <LazyIcon><Other /></LazyIcon>,
};

export default undefined;
