import { AlertTriangle, Check, XOctagon } from 'react-feather';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Div } from './Generic';
import { Icon } from './Icon';
import { UIProps } from '../../theme/mixins';
import SC from './SC';

interface AlertProps extends SC<AlertContainerProps> {
  icon?: ReactNode | null;
  children?: ReactNode;
}

export const Success = ({ icon = <Check />, children, sc, ...props }: AlertProps) => (
  <AlertContainer sc={{ icon: !!icon, type: 'success', ...sc }} {...props}>
    {icon && (
      <Icon>
        {icon}
      </Icon>
    )}
    <Div>
      {children}
    </Div>
  </AlertContainer>
);

export const Info = ({ icon, children, sc, ...props }: AlertProps) => (
  <AlertContainer sc={{ icon: !!icon, type: 'info', ...sc }} {...props}>
    {icon && (
      <Icon>
        {icon}
      </Icon>
    )}
    <Div>
      {children}
    </Div>
  </AlertContainer>
);

export const Warning = ({ icon = <AlertTriangle />, children, sc, ...props }: AlertProps) => (
  <AlertContainer sc={{ icon: !!icon, type: 'warning', ...sc }} {...props}>
    {icon && (
      <Icon>
        {icon}
      </Icon>
    )}
    <Div>
      {children}
    </Div>
  </AlertContainer>
);

export const Error = ({ icon = <XOctagon />, children, sc, ...props }: AlertProps) => (
  <AlertContainer sc={{ icon: !!icon, type: 'error', ...sc }} {...props}>
    {icon && (
      <Icon>
        {icon}
      </Icon>
    )}
    <Div>
      {children}
    </Div>
  </AlertContainer>
);

interface AlertContainerProps extends UIProps {
  type?: 'success' | 'info' | 'warning' | 'error' | 'atleta';
  icon?: boolean;
  rounded?: boolean;
}

export const AlertContainer = styled(Div)<SC<AlertContainerProps>>`
  ${({ sc: { type = 'info', icon = false, rounded = false } = {}, theme }) => {
    const borderColors = {
      success: theme.getColor('primary'),
      info: theme.getColor('secondary.200'),
      warning: theme.getColor('info'),
      error: theme.getColor('error.300'),
      atleta: theme.getColor('atleta_secondary.200'),
    };
    const backgroundColors = {
      success: theme.getColor('primary.25'),
      info: theme.getColor('secondary.50'),
      warning: theme.lightenColor(theme.getColor('info'), 45),
      error: theme.getColor('error.100'),
      atleta: theme.getColor('atleta_secondary.50'),
    };
    const textColors = {
      success: theme.getColor('primary.900'),
      info: theme.getColor('body'),
      warning: theme.desaturate(theme.darkenColor(theme.getColor('info'), 60), 90),
      error: theme.getColor('error.600'),
      atleta: theme.getColor('body'),
    };
    const iconColors = {
      success: theme.getColor('primary.600'),
      info: theme.getColor('secondary.500'),
      warning: theme.getColor('info'),
      error: theme.getColor('error.300'),
      atleta: theme.getColor('atleta_secondary.500'),
    };

    return css`
      background: ${backgroundColors[type]};
      border-left: 3px solid ${borderColors[type]};
      color: ${textColors[type]};
      padding: ${theme.gutter / 2}px;
      transition: all 0.15s ease-in-out;

      ${rounded && css`
        border-radius: ${theme.borderRadiuses.xl}px;
      `}

      ${icon && css`
        display: flex;
        justify-content: flex-start;

        > ${Icon}:first-child {
          min-width: 30px;
          width: 30px;
          color: ${iconColors[type]};
          transition: all 0.15s ease-in-out;
          margin: 3px 0 0 0;

          svg {
            width: 18px;
            height: 18px;
          }
        }

        > :nth-child(2) {
          width: 100%;
        }
      `}
    `;
  }}
`;

export default undefined;
